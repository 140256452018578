


































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {
  InviteInlenerAction,
  InlenerInvite, InlenerInviteListAction,
} from '@/store/inleners/types';
import VButton from '@/components/VButton.vue';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import createList from '@/utilities/lists';
import emailValidator from '@/utilities/validators/email';
import {AxiosResponse} from 'axios';
import UserInfo from '@/interfaces/userInfo';
import {toReadableDateFormat} from '@/utilities/filters';

@Component({
  components: {VButton},
  filters: {toReadableDateFormat},
})
export default class InlenersList extends Vue {

  get inviteInlenerFormValid() {
    return this.inviteInlenerForm.fieldValidity();
  }

  @Action('inlenerInvites', {namespace: 'inleners'}) private getInlenerInvites!: InlenerInviteListAction;
  @Action('inviteInlenerUser', {namespace: 'inleners'}) private inviteInlenerUser!: InviteInlenerAction;

  @Getter('userInfo', {namespace: 'account'}) private userInfo!: UserInfo;

  private showServerError = false;
  private serverError = '';

  private inlenerInvites = createList<InlenerInvite>({
    contains: '',
    page: 1,
    only_user: false,
  });

  private inviteInlenerForm: ValidatedForm = new ValidatedForm({
    email: new FormField(emailValidator, ''),
  });

  private created() {
    this.fetchInleners();
  }

  @Watch('inlenerInvites.filters.contains')
  @Watch('inlenerInvites.filters.page')
  private inlenersFilterWatcher() {
    this.fetchInleners();
  }

  private fetchInleners() {
    this.inlenerInvites.fetching = true;
    this.getInlenerInvites(this.inlenerInvites.filters).then((result) => {
      this.inlenerInvites = {
        ...this.inlenerInvites,
        ...result,
      };
    }).catch(() => {
      this.inlenerInvites.models = [];
    }).finally(() => this.inlenerInvites.fetching = false);
  }

  private submitInviteInlenerUser() {
    this.showServerError = false;
    if (this.inviteInlenerForm.formValid()) {
      this.inviteInlenerUser({
        email: this.inviteInlenerForm.fields.email.value,
      }).then((response) => {
        (this.$refs['inlener-invited-modal'] as any).show();
        this.inlenerInvites.models = response.models;
      }).catch((response: AxiosResponse) => {
        this.serverError = response.data[0];
        this.showServerError = true;
      });
    }
  }

  private routeToInlener(inlener: InlenerInvite) {
    this.$router.push({
      name: 'inlener-detail',
      params: {subscription_id: inlener.id?.toString() ?? inlener.contact},
    });
  }
}
